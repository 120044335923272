/* core */
html {
  height: 100%;
}
body {
  margin: 0;
  text-align: center;
  overflow-y: scroll;
}
p {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

.backdrop {
  background-color: #444;
	background-position: top;
	background-repeat: repeat;
  background-size: cover;
}
.backdrop.drip1 {
	background-image: url('assets/tiles/slate.png');
}
.backdrop.drip2 {
	background-image: url('assets/tiles/violet.png');
}
.backdrop.drip3 {
	background-image: url('assets/tiles/grass.png');
}
.backdrop.drip4 {
	background-image: url('assets/tiles/red.png');
}
.backdrop.drip5 {
	background-image: url('assets/tiles/pink.png');
}
.backdrop.drip6 {
	background-image: url('assets/tiles/orange.png');
}
.backdrop.drip7 {
	background-image: url('assets/tiles/green.png');
}
.backdrop.drip8 {
	background-image: url('assets/tiles/purple.png');
}
.backdrop.drip9 {
	background-image: url('assets/tiles/yellow.png');
}
.backdrop.drip10 {
	background-image: url('assets/backdrops/launch-1.png');
}
.backdrop.drip11 {
	background-image: url('assets/backdrops/garden-1.png');
}
.backdrop.drip12 {
	background-image: url('assets/backdrops/mix-1.png');
}

/* layout */
.box-default {
  float: left;
  width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
.box-default.relative {
  float: left;
  position: relative;
}
.box-default.relative.right {
  float: right;
}
.box-basic {
  width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

.box-inline {
  width: auto;
  display: inline-block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
  vertical-align: top;
}
.box-inline.noblock {
  display: inline;
}
.box-inline.left {
  float: left;
}
.box-inline.right {
  float: right;
}
.box-inline.relative {
  position: relative;
}

.box-abs {
  position: absolute;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
.box-abs.top {
  top: 0px;
}
.box-abs.left {
  left: 0px;
}
.box-abs.top-left {
  top: 0px;
  left: 0px;
}
.box-abs.top-right {
  top: 0px;
  right: 0px;
}
.box-abs.bottom {
  bottom: 0px;
}
.box-abs.bottom-left {
  bottom: 0px;
  left: 0px;
}
.box-abs.bottom-right {
  bottom: 0px;
  right: 0px;
}
.box-abs.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box-shift-center {
  position: relative;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.box-fixed {
  position: fixed;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
.box-fixed.top {
  top: 0px;
}
.box-fixed.top-left {
  top: 0px;
  left: 0px;
}
.box-fixed.bottom {
  bottom: 0px;
}
.box-fixed.bottom-left {
  bottom: 0px;
  left: 0px;
}

.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.overflow {
  overflow: visible !important;
}
.nooverflow {
  overflow: hidden !important;
}
.rounded {
  border-radius: 4px;
}
.roundedx {
  border-radius: 8px;
}
.noround {
  border-radius: 0px !important;
}
.zero-height {
  height: 0px;
  overflow: visible;
}
.pop-up {
  z-index: 3;
}
.pop-top {
  z-index: 9;
}
.pop-menu {
  z-index: 99;
}
.pop-max {
  z-index: 9999;
}

.end-width {
  width: 10% !important;
  display: inline-block;
}
.end2-width {
  width: 20% !important;
  display: inline-block;
}
.quarter-width {
  width: 25% !important;
  display: inline-block;
}
.third-end-width {
  width: 33% !important;
  display: inline-block;
}
.third-mid-width {
  width: 34% !important;
  display: inline-block;
}
.half-width {
  width: 50% !important;
  display: inline-block;
}
.q3-width {
  width: 75% !important;
  display: inline-block;
}
.shelf-width {
  width: 80% !important;
  display: inline-block;
}
.nt-width {
  width: 90% !important;
  display: inline-block;
}
.full-width {
  width: 100% !important;
}
.full-height {
  min-height: 150vh !important;
}
.full-height-before::before {
  min-height: 150vh !important;
}
.footer-height {
  min-height: 100vh !important;
}
.tab-height {
  min-height: 50vh !important;
}

/* links */
a {
  color: #f90;
  text-decoration: none;
  font-weight: 700;
  background-color: #000;
  opacity: 0.5;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
}
a:hover {
  opacity: 1;
}
a:active {
  opacity: 0.8;
}
a:visited {
  color: #f90;
}
a.image-link {
  background-color: transparent;
  padding: 0px;
  border-radius: 0px;
  opacity: 1;
}

/* typography */
/* ul, ol {
  margin-left: 0;
  padding-left: 0;
  text-align: center;
} */
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-paragraph {
  white-space: pre-line;
  word-wrap: break-word;
}
.text-justify {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
.text-fade {
  mask-image: linear-gradient(180deg, #000 60%, transparent);
}
.text-shadow {
  text-shadow: 1px 1px 1px #888;
}
.text-sit-right {
  float: right;
}

/* .text-fade:before {
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0;
  background:linear-gradient(transparent 150px, white);
} */
.lower-case {
  text-transform: lowercase;
}
.upper-case {
  text-transform: uppercase;
}
.proper-case {
  text-transform: capitalize;
}
.action-pointer {
  cursor: pointer;
}
.action-pointover {
  cursor: pointer;
  opacity: 1;
}
  .action-pointover:hover {
    opacity: 0.6;
  }
.action-pointbox {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
  .action-pointbox:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
.action-general {
  color: "#000" !important;
  text-decoration: none;
  cursor: pointer;
}
.content-disabled {
  opacity: 0.5;
}

/* borders shadows and effects */
.shadow-default {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px
}
.shadow-subtle {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0.8px 0.8px, rgba(0, 0, 0, 0.24) 0px 0.8px 0.8px
}
.shadow-main {
  box-shadow: inset 0 0 0 1px #ccc;
}
.shadow-basic {
  box-shadow: inset 0 0 0 1px #999;
}
.shadow-dark {
  box-shadow: inset 0 0 0 1px #555;
}
.shadow-light {
  box-shadow: inset 0 0 0 1px #ccc;
  border-radius: 4px;
}
.shadow-highlight {
  box-shadow: inset 0 0 0 1px #fcb;
  border-radius: 4px;
}
.shadow-selected {
  box-shadow: inset 0 0 0 3px #f90;
}
.shadow-input {
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #ddd;
  /* box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.20); */
}
.shadow-up {
  box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.20);
}
/* fade in when element is in view */
.fade-in {
  opacity: 0;
  animation: fade-in 0.5s ease-in-out forwards;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* fade out when element is in view */
.fade-out {
  opacity: 1;
  animation: fade-out 0.5s ease-in-out forwards;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.wormhole {
  position: relative;
}
.wormhole::before,
.wormhole::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 8px #fff;
  border-radius: 12px;
  z-index: -1;
}
.wormhole::before {
  left: -4px;
}
.wormhole::after {
  right: -4px;
}

.wormhole-horizontal {
  position: relative;
}
.wormhole-horizontal::before,
.wormhole-horizontal::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 8px #fff;
  border-radius: 12px;
  z-index: -1;
}
.wormhole-horizontal::before {
  top: -4px;
}
.wormhole-horizontal::after {
  bottom: -4px;
}

.bdp1 {
  padding: 1px;
}
.bdp2 {
  padding: 2px;
}
.bd-solid {
  border: 1px;
  border-style: solid;
}
.bd-dash {
  border: 1px;
  border-style: dashed;
}
.bd-dash-thick {
  border: 2px;
  border-style: dashed;
}
.bd-dark {
  border-color: #999;
}
.bd-light {
  border-color: #ddd;
}
.bd-top {
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
}
.bd-bottom {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}
.bd-left {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
}
.bd-right {
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
}
.fs-default {
  margin: 0;
  padding: 0;
  border: 1px solid #aaa;
  border-radius: 8px;
}
.fs-mid {
  margin: 0;
  padding: 0;
  border: 1px solid #555;
  border-radius: 8px;
}
.fs-highlight {
  margin: 0;
  padding: 0;
  border: 1px solid #f90;
  border-radius: 8px;
}
.fsl-left {
  margin-left: 16px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: left;
}
.fsl-center {
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
}
.fsl-right {
  margin-right: 18px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: right;
}
.fsl-zero {
  margin: 0px;
  padding: 0px;
}
.transition-children > * {
  transition: all 5s ease-in-out !important;
}
.transition-img-children > img {
  transition: all 5s ease-in-out !important;
}

/* auth codes */
.auth-container {
  width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
.auth-input {
  width: 16.6%;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 0px;
  border-radius: 5px;
  text-align: center;
  font-weight: 800;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}


/* media */
.bgi-cover {
	background-position: top;
	background-repeat: repeat;
  background-size: cover;
}
.bgi-contain {
	background-position: top;
	background-repeat: repeat;
  background-size: contain;
}

.bg-bots {
	background-image: url('assets/tiles/bots.png');
}
.bg-bots-t25 {
  background-color: #b0bec5;
	background-image: url('assets/tiles/bots-t25.png');
}
.bg-bots-t50 {
  background-color: #b0bec5;
	background-image: url('assets/tiles/bots-t50.png');
}

.bg-maps {
	background-image: url('assets/tiles/maps.png');
}
.bg-maps-t25 {
  background-color: #b0bec5;
	background-image: url('assets/tiles/maps-t25.png');
}
.bg-maps-t50 {
  background-color: #b0bec5;
	background-image: url('assets/tiles/maps-t50.png');
}

.bg-white {
  background-color: white;
}
.bg-white-t25 {
	background-image: url('assets/tiles/white-t25.png');
}
.bg-white-t50 {
	background-image: url('assets/tiles/white-t50.png');
}

.bg-black {
  background-color: black;
}
.bg-black-t10 {
	background-image: url('assets/tiles/black-t10.png');
}
.bg-black-t25 {
	background-image: url('assets/tiles/black-t25.png');
}
.bg-black-t50 {
	background-image: url('assets/tiles/black-t50.png');
}
.bg-black-t75 {
	background-image: url('assets/tiles/black-t75.png');
}
.bg-black-t90 {
	background-image: url('assets/tiles/black-t90.png');
}

.bg-highlight {
  background-color: #fae2be;
  color: "#000";
}
.bg-darklight {
  background-color: #371901fb;
}
.bg-moodlight {
  background-color: #330000;
}
.bg-dusk {
  background-color: #444444;
}
.bg-blgry {
  background-color: #eceff1;
}
.bg-danger {
  background-color: #ffebee;
}
.bg-unread {
  background-color: red;
  color: white;
  opacity: 0.8;
}
.bg-success {
  background-color: #003300;
}

.bar-image {
  width: 50;
  height: 50;
}

.app-badge-apple {
	width: 100px;
	margin-top: 8px;
}
.app-badge-google {
	width: 126px;
}

.pop-card {
  width: 300px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  background-color: #ffffff; /* Adjust background color */
  border-radius: 10px; /* Adjust border radius for rounded corners */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); /* Adjust shadow properties */
  position: relative;
  overflow: hidden;
}
.pop-card::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* Adjust gradient colors */
  pointer-events: none;
}


/* google maps */
.pac-container {
  z-index: 9999 !important;
}
@keyframes marker-drop {
  0% {
    transform: translateY(-200px) scaleY(0.9);
    opacity: 0;
  }
  5% {
    opacity: 0.7;
  }
  50% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
  65% {
    transform: translateY(-17px) scaleY(0.9);
    opacity: 1;
  }
  75% {
    transform: translateY(-22px) scaleY(0.9);
    opacity: 1;
  }
  100% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
}
.marker-drop {
  animation: marker-drop 0.3s linear forwards 0.5s;
}

/* wysiwyg editor */
.wysiwyg-wrapper {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #eee;
}
.wysiwyg-toolbar {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ddd;
  font: 0.8em sans-serif;
}
.wysiwyg-editor {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font: 0.8em sans-serif;
  min-height: 10rem;
}

/* marquee */
.marquee-ticker {
  width: 600px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 60vw;
}
.marquee-ticker-half {
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 40vw;
}

/* glow border */
.border-glow {
  display: inline-block;
  padding: 20px 40px;
  font-size: 24px;
  color: white;
  background-color: black;
  border-radius: 10px;
  position: relative;
  animation: border-glow 2s infinite alternate;
}
@keyframes border-glow {
  0% {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 25px #ff00ff, 0 0 30px #ff00ff, 0 0 35px #ff00ff;
  }
  100% {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff, 0 0 60px #ff00ff, 0 0 70px #ff00ff;
  }
}

/* scroll bar */
.custom-scrollbar {
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #555 #000; /* for Firefox (thumb color, track color) */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.custom-scrollbar:hover {
  scrollbar-width: thin; /* For Firefox */
}
.custom-scrollbar:hover::-webkit-scrollbar {
  display: block;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #000; /* Background of the scrollbar track */
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid #000; /* Padding around the thumb */
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color on hover */
}


/* other responsive */
@media only screen and (min-width: 600px) {
  p {
    margin-bottom: 10px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }
  .bar-image {
    width: 60;
    height: 60;
  }
  .app-badge-apple {
		width: 150px;
		margin-top: 12px;
	}
  .app-badge-google {
		width: 189px;
	}
  .marquee-ticker-half {
    width: 260px;
  }
};
@media only screen and (min-width: 900px) {
  p {
    margin-bottom: 12px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 18px;
  }
  .bar-image {
    width: 70;
    height: 70;
  }
  .app-badge-apple {
		width: 200px;
		margin-top: 16px;
	}
  .app-badge-google {
		width: 252px;
	}
  .marquee-ticker-half {
    width: 400px;
  }
};
@media only screen and (min-width: 1200px) {
  .bar-image {
    width: 80;
    height: 80;
  }
};
@media only screen and (min-width: 1500px) {
  .bar-image {
    width: 90;
    height: 90;
  }
};
@media only screen and (min-width: 1800px) {
  .bar-image {
    width: 100;
    height: 100;
  }
};

/* text and content effects */
.text-blur {
  filter: blur(5px);
  user-select: none;
  transition: filter 0.3s ease-in-out;
}
.text-blur .reveal {
  filter: blur(0px);
}

.content-blur {
  filter: blur(5px);
  user-select: none;
  transition: filter 0.3s ease-in-out;
}

.content-fade {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.content-fade-reveal {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* glower */
.glowing-text {
  font-size: 5rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #ff0080, #ff8000, #ffff00, #80ff00, #00ff80, #00ffff, #0080ff, #8000ff, #ff00ff);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: glow-text 3s ease-in-out infinite, shine-text 6s ease-in-out infinite;
  position: relative;
}
@keyframes glow-text {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes shine-text {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.4);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.9), 0 0 30px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.6);
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.4);
  }
}